<br>
<div class="row-centered row-flow">
    <div>
        <h2  class="title-home title bold">HOME</h2>
        <h2  class="title-improvement title bold">IMPROVEMENT</h2>
        <div class="container">
            <div class="mb-4  row justify-content-md-center">
              <div  class="col-md-6  mt-4 col-xs-6 col-12 col-lg-4">
                <h3  class="text-left title-article bold">Painting</h3>
                <p  class="article light">We wash the walls, repair holes, or remove old paint. Mixing, matching, and applying paints and other finishes to various surfaces. </p>
              </div>
              <div  class="col-md-6 col-xs-6 col-12 col-lg-4 mt-4">
                <h3 class="title-article text-left  bold">Concrete Repair</h3>
                <p class="article light">We fix a hardened concrete surface that over time has lost the ability to hold the binding concrete materials together due to damage or environmental exposure. </p>
              </div>
              <div  class="col-md-6 col-xs-6 col-12 col-lg-4 mt-4">
                <h3 class="title-article text-left bold">Carpentry</h3>
                <p class="article light" >A skilled trade and a craft in which the primary work performed is the cutting, shaping and installation of building materials during the construction of buildings, ships, timber bridges, concrete formwork, etc. </p>
              </div>
              <div  class="col-md-6 col-xs-6 col-12 col-lg-4 mt-4">
                <h3 class="title-article text-left bold">Kitchen Remodeling</h3>
                <p  class="article light">We redo some or all of the surfaces and often upgrading one or more appliances. </p>
              </div>
              <div  class="col-md-6 col-xs-6 col-12 col-lg-4 mt-4">
                <h3 class="title-article text-left  bold">Bathroom Remodeling</h3>
                <p  class="article light">Act of renewing; a making new after decay, destruction or deprivation. </p>
              </div>
              <div  class="col-md-6 col-xs-6 col-12  col-lg-4 mt-4">
                <h3 class="title-article text-left bold">Window Remodeling</h3>
                <p class="article light" >New installments of windows.  </p>
              </div>
              <div  class="col-md-6 col-xs-6 col-12 col-lg-4 mt-4">
                <h3  class="title-article text-left bold">Home Remodeling</h3>
                <p class="article light">We make improvements on an existing building or home.</p>
              </div>
              <div  class="col-md-6 col-xs-6 col-12 col-lg-4 mt-4">
                <h3 class="title-article text-left  bold">Deck Remodeling</h3>
                <p  class="article light">We renovate or redo the surface.  </p>
              </div>
              <div   class="col-md-12 col-xs-6 col-12 col-lg-4 final-div mt-4">
                <h3 class="title-article text-left bold">Garage Repair</h3>
                <p  class="article light" >We make modifications or improvements in your garage.</p>
              </div>
            </div>
            
               
            </div>
            
            
    </div>
</div>