<div mat-dialog-close class="btn-close-gallery" >
  <svg class="size-close" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
    <path d="M284.286 256.001L506.143 34.1432C513.954 26.3322 513.954 13.6682 506.143 5.85825C498.332 -1.95175 485.668 -1.95275 477.858 5.85825L256 227.716L34.1433 5.85825C26.3323 -1.95275 13.6682 -1.95275 5.85825 5.85825C-1.95175 13.6692 -1.95275 26.3332 5.85825 34.1432L227.715 256L5.85825 477.858C-1.95275 485.669 -1.95275 498.333 5.85825 506.143C9.76325 510.048 14.8822 512 20.0012 512C25.1202 512 30.2383 510.048 34.1443 506.143L256 284.286L477.857 506.143C481.762 510.048 486.881 512 492 512C497.119 512 502.237 510.048 506.143 506.143C513.954 498.332 513.954 485.668 506.143 477.858L284.286 256.001Z" fill="#FE813E"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="512.001" height="512.001" fill="white"/>
    </clipPath>
    </defs>
    </svg>  
</div>

<div mat-dialog-content style="height: 100%;     background-color: #f0f1f563;
   position: absolute; max-height: inherit;">
    <ngb-carousel  *ngIf="images">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img style="width: auto;
          height: 100vh" class="img-fluid"  [src]="images[0]" alt="Random first slide">
        </div>
      
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img style="width: auto;
          height: 100vh;" class="img-fluid" [src]="images[1]" alt="Random second slide">
        </div>
       
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img style="width: auto;
          height: 100vh;" class="img-fluid" [src]="images[2]" alt="Random third slide">
        </div>
    
      </ng-template>
    </ngb-carousel>
    
</div> 


 