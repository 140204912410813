import { HostListener,Component, OnInit } from '@angular/core';
import {  faPhoneAlt, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons';
import { trigger, state, style,animate,transition } from '@angular/animations';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    
  ]
})
export class AppComponent implements OnInit {
  title = 'solid-stone-app';
  faPhoneAlt = faPhoneAlt;
  faEnvelope = faEnvelope;
  faBars=faBars; 
  seconColorNav: boolean;
  hiddenProject: boolean;
  show = false;

  constructor(){

  }
  ngOnInit(): void{

  }


  get stateName(){
    return this.show ? 'show':'hide'
  }


  toggle(){
    this.show = !this.show;
  }




  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  changeColorNav(n){
    this.seconColorNav = n;
    console.log(this.seconColorNav);
  }


  public isCollapsed = true;

  myFunction(){
    console.log('QUe oi')
  }

  
  

  // @HostListener('scroll', ['$event']) // for scroll events of the current element
  @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event) {
      this.seconColorNav = true;
      
      let scrollOffset = event.srcElement.children[0].scrollTop;
     

      if (scrollOffset == 0){
        this.seconColorNav = false;
      }

  }
  
}
