<div class="row-flow">
  <div class="col-container">
      <div class="overlay"></div>
      <div  class="column-1 col-r">
          <div class="row-sponsered">
            <h2  class="medium letter-big-white">the success <br>
            <span  class="bold"> is under</span><br>
            <span  class="bold"> construction.</span><br>
            </h2> 
            <div class="ml-1 row">
              <div class="icon-location">
              </div>
              <div class="address">
                <span  class="letter-small-white semi-bold">100 west street, 06810, Danbury CT, USA </span>
              </div>
              <div class="icon-personal" >
              </div>
              <div class="personal-info">
                <span  class="letter-small-white semi-bold">info@solidstonemasonry.com +1(203) 570 9640</span>
              </div>
            </div>
          </div>
          
      </div>
  
      <div class="column-2 col-r">
          
          <div>
            <div class="form-contact" >
              <h2 style="color: #FFF;" class="semi-bold">Contact Us</h2>
              <form class="example-form" [formGroup]="contactDataFormGroup"  (ngSubmit)="submitContact()" #formContactData="ngForm">
                  <mat-form-field style="width: 100%;" class="mb-4">
                    <mat-label style="color: #FFF;" class="medium" >Name*</mat-label>
                    <input  formControlName="name" matInput   id="name"  style="color: #FE813E;" class="light" matInput  >
                    <mat-error  class="light"  style="color: #FE813E;" *ngIf="closeError == false && f1Ctrl.name.invalid && formContactData.submitted">
                      Please enter your name
                    </mat-error>
                  </mat-form-field>



                  <mat-form-field style="width: 100%;" class="example-full-width mb-4" >
                    <mat-label style="color: #FFF;" class="medium">Email*</mat-label>
                    <input  id="email"  style="color: #FE813E;" matInput  type="email" matInput formControlName="email" >
                    <mat-error  style="color: #FE813E;" class="medium"  *ngIf="closeError == false && f1Ctrl.email.hasError && !formContactData.hasError('required')">
                      Please enter an email address
                    </mat-error>
                    <!-- <mat-error  style="color: #FE813E;" class="medium"  *ngIf="closeError == false && f1Ctrl.email.invalid && formContactData.submitted">
                      Email is  <strong>required</strong>
                    </mat-error> -->
                  </mat-form-field>

                  <mat-form-field style="width: 100%;" class="mb-4">
                    <mat-label style="color: #FFF;" class="semi-bold" >Phone</mat-label>
                    <input  formControlName="phone" matInput   id="phone"  style="color: #FE813E;" class="light" matInput  >
                  </mat-form-field>

                  <mat-form-field  style="width: 100%;" class="mb-4">
                    <mat-label style="color: #FFF;" class="semi-bold" >Tell us, how can we help you*</mat-label>
                    <textarea formControlName="description" id="body" style="color: #FE813E;" matInput  ></textarea>
                    <mat-error  style="color: #FE813E;" class="medium"  *ngIf="closeError == false && f1Ctrl.description.hasError && !formContactData.hasError('required')">
                      Please tell us your idea
                    </mat-error>
                  </mat-form-field>

                  <button  onclick="sendEmail()" mat-button type="submit" class="button-send bold">send</button>
                
              </form>

              <div class="social-media">
                <div class="row">
                  <div style="margin-left: auto;" class="form-inline">
                    <a style="z-index: 1;" class="mr-2" href="http://instagram.com/solidstonemasonry?igshid=13xhcgnvk1ufk" target="_blank" rel="noopener noreferrer"><div class="icon-instagram"></div></a>
            
                    <a style="z-index: 1;" class="mr-3" href="https://www.facebook.com/solidstoneusa/" target="_blank" rel="noopener noreferrer"><div class="icon-facebook"></div></a>  
                  </div>
                </div>
                <p   class="letter-small-white semi-bold text-right">© COPYRIGHT 2024 | Powered By <a href="https://artandcolor.io" target="_blank" rel="noopener noreferrer">Art & Color.</a>  All Rights Reserved</p>
              </div>
            </div>
          </div>
      </div>

      
  </div> 


    
  <div class="contact-responsive">
          
      
       
     
    <div class="col-12 column-2-responsive">
      <h2 style="color: #FFF;" class="semi-bold">Contact Us</h2>
      <form  form class="example-form" [formGroup]="contactDataFormGroupRes"  (ngSubmit)="submitContactRes()" #formContactDataRes="ngForm">
        <mat-form-field style="width: 100%;" class="mb-4">
          <mat-label style="color: #FFF;" class="medium" >Name*</mat-label>
          <input  formControlName="name_res" matInput   id="name_res"  style="color: #FE813E;" class="light" matInput  >
          <mat-error  class="light"  style="color: #FE813E;" *ngIf="closeError == false && f1CtrlRes.name_res.invalid && formContactDataRes.submitted">
            Please enter your name
          </mat-error>
        </mat-form-field>
      
        <mat-form-field style="width: 100%;" class="example-full-width mb-4" >
          <mat-label style="color: #FFF;" class="medium">Email*</mat-label>
          <input  id="email_res"  style="color: #FE813E;" matInput  type="email" matInput formControlName="email_res" >
          <mat-error  style="color: #FE813E;" class="medium"  *ngIf="closeError == false && f1Ctrl.email.hasError && !formContactData.hasError('required')">
            Please enter an email address
          </mat-error>
          <!-- <mat-error  style="color: #FE813E;" class="medium"  *ngIf="closeError == false && f1Ctrl.email.invalid && formContactData.submitted">
            Email is  <strong>required</strong>
          </mat-error> -->
        </mat-form-field>

        <mat-form-field style="width: 100%;" class="mb-4">
          <mat-label style="color: #FFF;" class="semi-bold" >Phone</mat-label>
          <input  formControlName="phone_res" matInput type="number"   id="phone_res"  style="color: #FE813E;"  matInput  >
        </mat-form-field>

        <mat-form-field  style="width: 100%;" class="mb-4">
          <mat-label style="color: #FFF;" class="semi-bold" >Tell us, how can we help you*</mat-label>
          <textarea formControlName="description_res" id="body_res" style="color: #FE813E;" matInput  ></textarea>
          <mat-error  style="color: #FE813E;" class="medium"  *ngIf="closeError == false && f1Ctrl.description.hasError && !formContactData.hasError('required')">
            Please tell us your idea
          </mat-error>
        </mat-form-field>

        <button  onclick="sendEmailRes()" mat-button type="submit" class="button-send bold">send</button>
      </form>

     

      
      
    </div>

    <div class="col-12 column-1-responsive">
      
      <h2  class="medium letter-big-white">the success <br>
        <span  class="bold"> is under</span><br>
        <span  class="bold"> construction.</span><br>
       </h2> 
       <div class="ml-1 row">
         <div class="icon-location">
         </div>
         <div class="address mb-3">
           <span  class="letter-small-white semi-bold">100 west street, 06810, Danbury CT, USA </span>
         </div>
         
       </div>
       <div class="ml-1 row">
        <div class="icon-personal" >
        </div>
        <div class="personal-info">
          <span  class="letter-small-white semi-bold">info@solidstonemasonry.com +1(203) 570 9640</span>
        </div>
        <div class="social-media ml-auto mr-3 mt-3">
            <div  class="form-inline">
              <a class="ml-auto mr-2" href="http://instagram.com/solidstonemasonry?igshid=13xhcgnvk1ufk" target="_blank" rel="noopener noreferrer"><div class="icon-instagram"></div></a>
              <a href="https://www.facebook.com/solidstoneusa/" target="_blank" rel="noopener noreferrer"><div class="icon-facebook"></div></a>  
            </div>
            <p style="font-size: 2.7vw;"  class="letter-small-white semi-bold text-right">© COPYRIGHT 2024 | Powered By <a href="https://artandcolor.io" target="_blank" rel="noopener noreferrer">Art & Color.</a>  All Rights Reserved</p>
        </div>
       </div>
     </div>
  </div>
</div>
