import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-gallery',
  templateUrl: './modal-gallery.component.html',
  styleUrls: ['./modal-gallery.component.scss']
})
export class ModalGalleryComponent implements OnInit {
  img1:string;
  img2:string;
  img3:string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {img1: string, img2:string, img3:string},
  ) { }

  ngOnInit(): void {
    this.img1 = this.data.img1;
    this.img2 = this.data.img2;
    this.img3 = this.data.img3;

    // console.log(this.img1, this.img2, this.img3);

    
  }

  images = [ this.data.img1,this.data.img2, this.data.img3].map((n) => `../assets/img/${n}`);

}
