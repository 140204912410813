import { Component, OnInit } from '@angular/core';
import {FormControl,FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactDataFormGroup: FormGroup;
  contactDataFormGroupRes: FormGroup;
  closeError = false;
  constructor(
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.contactDataFormGroup = new FormGroup({
      name: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required, Validators.email]),
      phone: new FormControl(''),
      description: new FormControl('',[Validators.required])
    });

    this.contactDataFormGroupRes = new FormGroup({
      name_res: new FormControl('',[Validators.required]),
      email_res: new FormControl('',[Validators.required, Validators.email]),
      phone_res: new FormControl(''),
      description_res: new FormControl('',[Validators.required])
    });
    
    
  }

  get f1Ctrl() { return this.contactDataFormGroup.controls }
  get f1CtrlRes() { return this.contactDataFormGroupRes.controls }


  submitContact(){
    if (this.contactDataFormGroup.valid){
      this._snackBar.open('your mail sent','successfully', {
        duration: 3000,
      });
      this.contactDataFormGroup.reset();
      this.closeError = true;
    }else{
      this.closeError = false;
    }

  }

  submitContactRes(){
    if (this.contactDataFormGroupRes.valid){
      this._snackBar.open('your mail sent','successfully', {
        duration: 3000,
      });
      this.contactDataFormGroupRes.reset();
      this.closeError = true;
    }else{
      this.closeError = false;
    }

  }


  
 

}
