<div class="row-flow" >
  
    <div class="col-container">
        
        <h2  class="title bold">Projects</h2>
        
        <div  *ngIf="nextProjects === false"  class="column-1 col-r">
            <div class="content-title-project">
                <h3  class="title-projects text-center semi-bold">BLUE STONE PATH <br> ( NEW FAIRFIELD, CT)</h3>
            </div>
            <div (click)="openGallery('image-project-1-1.jpg','image-project-1-2.jpg','image-project-1-3.jpg')"  class="postion-eye-1 btn-eye">
              <div class="eyes text-center">
                <div class="btn-viewmore">
                  <span class="label-more bold" >View more</span>
                </div>
              </div>
            </div>
            
        </div>
    
        <div *ngIf="nextProjects === false" class="column-2 col-r">
            <div class="content-title-project">
                <h3 style="text-transform: uppercase;" class="title-projects text-center semi-bold">blue stone patio <br> and retaining wall  <br> ( New Fairfiel, CT )</h3>
            </div>
            <div (click)="openGallery('image-project-2-1.jpg','image-project-2-2.jpg','image-project-2-3.jpg')"   class="postion-eye-1 btn-eye">
              <div class="eyes text-center">
                <div class="btn-viewmore">
                  <span class="label-more bold" >View more</span>
                </div>
              </div>
            </div>
        </div>

        <div *ngIf="nextProjects === false" class="column-3 col-r">
            <div class="content-title-project">
                <h3 style="text-transform: uppercase;" class="title-projects text-center semi-bold">granite patio and vinyl stone <br> (  New Haven, CT ) </h3>
            </div>
            <div (click)="openGallery('image-project-3-1.jpg','image-project-3-2.jpg','image-project-3-3.jpg')"   class="postion-eye-1 btn-eye">
              <div class="eyes text-center">
                <div class="btn-viewmore">
                  <span class="label-more bold" >View more</span>
                </div>
              </div>
            </div>
        </div>

        <div *ngIf="nextProjects === false"  class="column-4 col-r">
            <div class="content-title-project">
                <h3 style="text-transform: uppercase;" class="title-projects text-center semi-bold">natural stone path  <br> (Danbury, CT)</h3>
            </div>
            <div (click)="openGallery('image-project-4-1.jpg','image-project-4-2.jpg','image-project-4-3.jpg')"   class="postion-eye-1 btn-eye">
              <div class="eyes text-center">
                <div class="btn-viewmore">
                  <span class="label-more bold" >View more</span>
                </div>
              </div>
            </div>
        </div>

        <div *ngIf="nextProjects === true"  class="column-5 col-r">
            <div class="content-title-project">
                <h3 style="text-transform: uppercase;" class="title-projects text-center semi-bold">natural stone patio <br> (Danbury,CT)</h3>
            </div>
            <div (click)="openGallery('image-project-5-1.jpg','image-project-5-2.jpg','image-project-5-3.jpg')"  class="postion-eye-1 btn-eye">
              <div class="eyes text-center">
                <div class="btn-viewmore">
                  <span class="label-more bold" >View more</span>
                </div>
              </div>
            </div>
        </div>
    
        <div *ngIf="nextProjects === true" class="column-6 col-r">
            <div class="content-title-project">
                <h3 style="text-transform: uppercase;" class="title-projects text-center semi-bold">natural stone pillars <br> ( New Fairfiel, CT )</h3>
            </div>
            <div (click)="openGallery('image-project-6-1.jpg','image-project-6-2.jpg','image-project-6-3.jpg')"   class="postion-eye-1 btn-eye">
              <div class="eyes text-center">
                <div class="btn-viewmore">
                  <span class="label-more bold" >View more</span>
                </div>
              </div>
            </div>
        </div>

        <div *ngIf="nextProjects === true" class="column-7 col-r">
            <div class="content-title-project">
                <h3  class="title-projects text-center semi-bold">patio pavers <br> ( Berlin, CT )</h3>
            </div>
            <div (click)="openGallery('image-project-7-1.jpg','image-project-7-2.jpg','image-project-7-3.jpg')"   class="postion-eye-1 btn-eye">
              <div class="eyes text-center">
                <div class="btn-viewmore">
                  <span class="label-more bold" >View more</span>
                </div>
              </div>
            </div>
        </div>

        <div *ngIf="nextProjects === true"    class="column-8 col-r">
            <div class="content-title-project">
                <h3 style="text-transform: uppercase;" class="title-projects text-center semi-bold">vinyl stone fireplace  <br> ( New Preston, CT ) </h3>
            </div>
            <div (click)="openGallery('image-project-8-1.jpg','image-project-8-2.jpg','image-project-8-3.jpg')"   class="postion-eye-1 btn-eye">
              <div class="eyes text-center">
                <div class="btn-viewmore">
                  <span class="label-more bold" >View more</span>
                </div>
              </div>
            </div>
        </div>

        <button style="background-color: #FE813E;" mat-button  [ngStyle]="{'left':nextProjects === true ? '0' : 'auto' }" class="btn button-navigator" (click)="nextProjectsImage()"><img *ngIf="nextProjects == true"  src="assets/img/arrow-left.svg" alt=""> <img *ngIf="nextProjects == false" src="assets/img/arrow-right.svg" alt=""></button>       
       
    </div> 

   
    
</div>

<div class="project-responsive">
  <h3 class="title-projects-responsive bold">Projects</h3>
  <ngb-carousel style="z-index: 1; position: relative; height: 100vh;" *ngIf="images">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img style="height: 100vh; width: 100vw;"  [src]="images[0]" alt="Random first slide">
      </div>
      <div class="carousel-caption">
        <h3 style="font-size: 2rem; text-transform: uppercase; visibility: hidden;" class="bold">Projects</h3>
        <br>
        <p class="semi-bold">BLUE STONE PATH <br> ( NEW FAIRFIELD, CT)</p>
        <div class="contect-button-view-more-small" >
          <div  class="btn-viewmore-small" (click)="openGallery('image-project-1-1.jpg','image-project-1-2.jpg','image-project-1-3.jpg')">
            <span class="label-more-small medium">
              View more
            </span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img style="height: 100vh; width: 100vw;"  [src]="images[1]" alt="Random second slide">
      </div>
      <div class="carousel-caption">
        <h3 style="visibility: hidden;font-size: 2rem; text-transform: uppercase;" class="bold">Projects</h3>
        <br>
        <p style="text-transform: uppercase;"  class="semi-bold">blue stone patio <br> and retaining wall  <br> ( New Fairfiel, CT )
          </p>
        <div>
          <div class="contect-button-view-more-small" >  
            <div class="btn-viewmore-small" (click)="openGallery('image-project-2-1.jpg','image-project-2-2.jpg','image-project-2-3.jpg')">
              <span class="label-more-small medium">
                View more
              </span>
            </div>
          </div>
        </div>
        
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img style="height: 100vh; width: 100vw;"  [src]="images[2]" alt="Random third slide">
      </div>
      <div class="carousel-caption">
        <h3 style="visibility: hidden;font-size: 2rem; text-transform: uppercase;" class="bold">Projects</h3>
        <br>
        <p style="text-transform: uppercase;" class="semi-bold">granite patio and vinyl stone <br> (  New Haven, CT )</p>
        <div class="contect-button-view-more-small" >
          <div class="btn-viewmore-small" (click)="openGallery('image-project-3-1.jpg','image-project-3-2.jpg','image-project-3-3.jpg')">
            <span class="label-more-small medium">
              View more
            </span> 
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img style="height: 100vh; width: 100vw;"  [src]="images[3]" alt="Random third slide">
        </div>
        <div class="carousel-caption">
          <h3 style="visibility: hidden;font-size: 2rem; text-transform: uppercase;" class="bold">Projects</h3>
          <br>
          <p style="text-transform: uppercase;" class="semi-bold">natural stone path  <br> (Danbury, CT)
          </p>
          <div class="contect-button-view-more-small" >
            <div class="btn-viewmore-small" (click)="openGallery('image-project-4-1.jpg','image-project-4-2.jpg','image-project-4-3.jpg')">
              <span class="label-more-small medium">
                View more
              </span> 
            </div>
          </div>
            
        </div>
    </ng-template>
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img style="height: 100vh; width: 100vw;"  [src]="images[4]" alt="Random third slide">
        </div>
        <div class="carousel-caption">
          <h3 style="visibility: hidden;font-size: 2rem; text-transform: uppercase;" class="bold">Projects</h3>
          <br>
          <p style="text-transform: uppercase;" class="semi-bold">natural stone patio <br> (Danbury,CT)
          </p>
          <div class="contect-button-view-more-small" >
            <div class="btn-viewmore-small" (click)="openGallery('image-project-5-1.jpg','image-project-5-2.jpg','image-project-5-3.jpg')">
              <span class="label-more-small medium">
                View more
              </span> 
            </div>
          </div>
        </div>
    </ng-template>
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img style="height: 100vh; width: 100vw;"  [src]="images[5]" alt="Random third slide">
        </div>
        <div class="carousel-caption">
          <h3 style="visibility: hidden;font-size: 2rem; text-transform: uppercase;" class="bold">Projects</h3>
          <br>
          <p style="text-transform: uppercase;" class="semi-bold">natural stone pillars <br> ( New Fairfiel, CT )
          </p>
          <div class="contect-button-view-more-small" >
            <div class="btn-viewmore-small" (click)="openGallery('image-project-6-1.jpg','image-project-6-2.jpg','image-project-6-3.jpg')">
              <span class="label-more-small medium">
                View more
              </span>
            </div>
          </div>
        </div>
    </ng-template>
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img style="height: 100vh; width: 100vw;"  [src]="images[6]" alt="Random third slide">
        </div>
        <div class="carousel-caption">
          <h3 style="visibility: hidden;font-size: 2rem; text-transform: uppercase;" class="bold">Projects</h3>
          <br>
          <p style="text-transform: uppercase;" class="semi-bold">patio pavers <br> ( Berlin, CT ) </p>
          <div class="contect-button-view-more-small" >
            <div class="btn-viewmore-small" (click)="openGallery('image-project-7-1.jpg','image-project-7-2.jpg','image-project-7-3.jpg')">
              <span class="label-more-small medium">
                View more
              </span>
            </div>
          </div>
        </div>
    </ng-template>
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img style="height: 100vh; width: 100vw;"  [src]="images[7]" alt="Random third slide">
        </div>
        <div class="carousel-caption">
          <h3 style="visibility: hidden;font-size: 2rem; text-transform: uppercase;" class="bold">Projects</h3>
          <br>
          <p style="text-transform: uppercase;" class="semi-bold">vinyl stone fireplace  <br> ( New Preston, CT )
          </p>
          <div class="contect-button-view-more-small" >
            <div class="btn-viewmore-small" (click)="openGallery('image-project-8-1.jpg','image-project-8-2.jpg','image-project-8-3.jpg')">
              <span class="label-more-small medium">
                View more
              </span>
            </div>
          </div>
        </div>
    </ng-template>
  </ngb-carousel>
</div>

