<!-- <p style="visibility: hidden;"> services works!</p> -->

<div  class="row-flow">
    <div  class="container text-center">
        <br>
        <div  class="content-service">
            <h2  class="text-center title bold">Services</h2>
            
            <p class="light">Give the best quality to our clients by presenting innovative projects and offering them our services with a team of highly experienced workers. </p>
        </div>
        
    </div>
    <div class="mt-5 col-container">
        
        <div class="col-r column-1">
        </div>
    
        <div  class="hvr-grow column-2 col-r container">
           <div class="content-column-2 row">
              <span ><h2 class="title-column-orange semi-bold">Pool Repairs</h2>
                <p class="articule-column light">We fix any major cracks or leaks in the swimming pool structure. </p>
              </span> 
              
           </div>
        </div>
    
        <div class="hvr-grow column-3 col-r">
            <span  class=" articule-column-3"><h2 class="title-column-gray semi-bold">Side Wall</h2>
                <p  class="articule-column light">A wall that forms the side <br> of something.  </p>
            </span> 
        </div>
        <div  class="hvr-grow column-4 col-r">
            <span class=" articule-column-4"><h2 class="title-column-gray semi-bold">Patio</h2>
                <p class="articule-column light">The recreation area that adjoins a dwelling, is often paved, and is adapted especially to outdoor dining.  </p>
            </span> 
        </div>
    </div> 
    <div class="col-container">
        
        <div class="hvr-grow col-r column-5">
            <div  class=" block">
                <span ><h2 class="title-column-gray semi-bold">Brick & Block Work</h2>
                    <p class="articule-column light">Components made of durable masonry construction in which uniformly shaped individual units are laid in courses with mortar as the bed and binding material. </p>
                </span>    
            </div>
             
        </div>
    
        <div  class="hvr-grow column-6 col-r container">
           <div class=" content-column-2 row">
              <span><h2 class="title-column-gray semi-bold">Retaining Walls</h2>
                <p class="articule-column light">A structure designed and constructed to resist the lateral pressure of soil, when there is a desired change in ground elevation that exceeds the angle of repose of the soil. </p>
              </span> 
              
           </div>
        </div>
    
        <div class="hvr-grow column-7 col-r">
            <span><h2 class="title-column-white semi-bold">Chimney Repair</h2>
                <p class="articule-column-white light">We remove and replace the mortar between stone, block, or brick.   </p>
            </span> 
        </div>
        <div  class="column-8 col-r">
             
        </div>
    </div> 
    <div class="col-container">
        
        <div class="col-r column-9">
        </div>
    
        <div  class="hvr-grow column-10 col-r container">
              <span><h2 class="title-column-orange semi-bold">Belgium Blocks</h2>
                <p class="articule-column light">Are made of granite and used for paving, borders and a wide variety of landscaping projects.  </p>
              </span> 
        </div>
    
        <div class="hvr-grow column-11 col-r">
            <span ><h2 class="title-column-gray semi-bold">Fire Pit</h2>
                <p  class="articule-column light">We make a pit dug in the ground to an elaborate gas burning structure of stone, brick, and metal. </p>
            </span> 
        </div>
        <div  class="hvr-grow column-12 col-r">
            <span ><h2 class="title-column-gray semi-bold">Pavers</h2>
                <p  class="articule-column light">Paving stones, tile, brick, or brick-like pieces of concrete commonly used as exterior flooring or stepping stones.    </p>
            </span> 
        </div>
    </div>
</div>
